import { useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import "./MainNavigation.css";

const StyledMenu = styled.nav`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #000b1f;
  transform: ${({ open }) => (open ? "translateX(0)" : "translateX(-100%)")};
  height: 100vh;
  width: 100vw;
  text-align: left;
  padding: 2rem;
  position: absolute;
  top: 0;
  left: 0;
  transition: transform 0.3s ease-in-out;
  z-index: 100;
  position: ${({ open }) => (open ? "fixed" : "null")};
  @media (max-width: 576px) {
    width: 100vw;
  }
  a {
    font-size: 2rem;
    padding: 2rem 0;
    font-weight: bold;
    letter-spacing: 0.1rem;
    color: #ffffff;
    text-decoration: none;
    transition: color 0.3s linear;
    text-align: center;
    @media (max-width: 576px) {
      font-size: 1.5rem;
    }
    &:hover {
      color: #343078;
    }
  }
`;

const StyledBurger = styled.button`
  position: absolute;
  top: 4rem;
  right: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 101;
  position: ${({ open }) => (open ? "fixed" : "null")};
  &:focus {
    outline: none;
  }
  @media (min-width: 910px) {
    display: none;
  }
  div {
    width: 2rem;
    height: 0.25rem;
    background: #ffffff;
    border-radius: 10px;
    transition: all 0.3s linear;
    position: relative;
    transform-origin: 1px;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
    }
    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
    }
    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
    }
  }
`;

const Burger = ({ open, setOpen }) => {
  return (
    <StyledBurger open={open} onClick={() => setOpen(!open)}>
      <div />
      <div />
      <div />
    </StyledBurger>
  );
};

const Menu = ({ open, handleLinkClick, isActive }) => {
  return (
    <StyledMenu open={open}>
      <NavLink to="/" onClick={handleLinkClick} className={`${isActive ? "active" : null}`}>
        EU Overview
      </NavLink>
      <NavLink to="/child-safety" onClick={handleLinkClick} className={`${isActive ? "active" : null}`}>
        EU Child Safety
      </NavLink>
      <NavLink to="/general-assessment" onClick={handleLinkClick} className={`${isActive ? "active" : null}`}>
        General Assessment
      </NavLink>
      <a
            className="checkstep-logo-burger"
            href="https://www.checkstep.com/"
            target="_blank"
            rel="noreferrer"
          >
            <span>Powered by</span>
          </a>
    </StyledMenu>
  );
};
function BurgerNavigation() {

  const [open, setOpen] = useState(false);
  const node = useRef();
  const [isActive, setActive] = useState(false);

  function handleLinkClick() {
    setActive(false);
    setOpen(false)
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }
  return (
    <>
      <nav className="TopNavigation">
        <div ref={node}>
          <Burger open={open} setOpen={setOpen} />
          <Menu open={open} setOpen={setOpen} handleLinkClick={handleLinkClick} isActive={isActive}/>
        </div>
      </nav>
    </>
  );
}
export default BurgerNavigation;
