export const csDetail = [
    {
      Requirement: "Consent (minors cannot consent to processing)",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Age verification",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Perental controls",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Protection from age innapropirate content & Ads",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "requirement to report CSAM to authorities",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Ban targeted advertising to minors",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Ban algorythmic content that could be harmful",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Data mapping for minors",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Turn off behavoural advertising",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Add chat filters",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Obtain parental consent to use children's data",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Parent must use credit card to verify age",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    },
    {
      Requirement: "Assessment of service for minors",
      GDPR: '',
      "UK DPA": '',
      ePD: '',
      "EU AI Act": '',
      DSA: '',
      OSB: '',
      "EU AVMSD": '',
      AADC: '',
      COPPA: ''
    }
  ];