import { useState } from "react";
import { NavLink } from "react-router-dom";
import BurgerNavigation from './BurgerNavigation.jsx'
import logo from '../assets/osglogoclear.png'
import "./MainNavigation.css";

function MainNavigation(props) {
  const [isActive, setActive] = useState(false);

  function handleLinkClick() {
    setActive(false);

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <nav className="mainNavigation">
        <BurgerNavigation />
          <img src={logo} alt="osg-logo" className="logo" />
          <div className="navButton-container">
            <div
              className={`navButton ${({ isActive }) =>
                isActive ? "active" : null}`}
            >
              <NavLink onClick={handleLinkClick} to="/">
                EU Overview
              </NavLink>
            </div>
            <div
              className={`navButton ${({ isActive }) =>
                isActive ? "active" : null}`}
            >
              <NavLink onClick={handleLinkClick} to="/child-safety">
                EU Child Safety
              </NavLink>
            </div>
            <div
              className={`navButton ${({ isActive }) =>
                isActive ? "active" : null}`}
            >
              <NavLink onClick={handleLinkClick} to="/general-assessment">
                General Assessment
              </NavLink>
            </div>
          </div>
          <a
            className="checkstep-logo"
            href="https://www.checkstep.com/"
            target="_blank"
            rel="noreferrer"
          >
            <span>Powered by</span>
          </a>
      </nav>
    </>
  );
}

export default MainNavigation;
