export const csData = [
    {
      Requirement: "Consent (minors cannot consent to processing)",
      "GDPR": true,
      "UK DPA": true,
      "ePD": true,
      "EU AI Act": false,
      "DSA": false,
      "OSB": true,
      "EU AVMSD": true,
      "AADC": true,
      "COPPA": false
    },
    {
      Requirement: "Age verification",
      "GDPR": false,
      "UK DPA": false,
      "ePD": false,
      "EU AI Act": false,
      "DSA": false,
      "OSB": false,
      "EU AVMSD": true,
      "AADC": true,
      "COPPA": true
    },
    {
      Requirement: "Perental controls",
      "GDPR": false,
      "UK DPA": false,
      "ePD": false,
      "EU AI Act": false,
      "DSA": true,
      "OSB": true,
      "EU AVMSD": true,
      "AADC": true,
      "COPPA": false
    },
    {
      Requirement: "Protection from age innapropirate content & Ads",
      "GDPR": false,
      "UK DPA": false,
      "ePD": false,
      "EU AI Act": false,
      "DSA": true,
      "OSB": true,
      "EU AVMSD": true,
      "AADC": true,
      "COPPA": true
    },
    {
      Requirement: "requirement to report CSAM to authorities",
      "GDPR": false,
      "UK DPA": false,
      "ePD": false,
      "EU AI Act": false,
      "DSA": true,
      "OSB": true,
      "EU AVMSD": true,
      "AADC": true,
      "COPPA": false
    },
    {
      Requirement: "Ban targeted advertising to minors",
      "GDPR": false,
      "UK DPA": false,
      "ePD": false,
      "EU AI Act": false,
      "DSA": true,
      "OSB": false,
      "EU AVMSD": false,
      "AADC": true,
      "COPPA": true
    },
    {
      Requirement: "Ban algorythmic content that could be harmful",
      "GDPR": false,
      "UK DPA": false,
      "ePD": false,
      "EU AI Act": false,
      "DSA": true,
      "OSB": true,
      "EU AVMSD": false,
      "AADC": true,
      "COPPA": false
    },
    {
      Requirement: "Data mapping for minors",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: true,
      COPPA: false
    },
    {
      Requirement: "Turn off behavoural advertising",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: false,
      "EU AVMSD": false,
      AADC: true,
      COPPA: true
    },
    {
      Requirement: "Add chat filters",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: true,
      COPPA: false
    },
    {
      Requirement: "Obtain parental consent to use children's data",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: true,
      "EU AVMSD": false,
      AADC: false,
      COPPA: true
    },
    {
      Requirement: "Parent must use credit card to verify age",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false,
      COPPA: true
    },
    {
      Requirement: "Assessment of service for minors",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: true,
      "EU AVMSD": false,
      AADC: false,
      COPPA: true
    }
  ];