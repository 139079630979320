export const euData = [
    {
      Requirement: "Appeal decisions made by automated means",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Appeal any decision",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Restrict processing",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Delete data",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Rectify information",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Object to processing",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Access their data",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Port data",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
    },
    {
      Requirement: "Transparency about processing",
      GDPR: true,
      "UK DPA": true,
      ePD: true,
      "EU AI Act": true,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: true
    },
    {
      Requirement: "Report personal safety / threat to life to law enforcement",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: false,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "Reporting for illegal content",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "Ban persistent users who post illegal content",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: false,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "Prioritise reports from 'trusted flaggers' designated by digital service coordinators",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: false,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "Transparency for recommendation algorithms",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": true,
      DSA: true,
      OSB: false,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "Enhanced protections for minors (ie ban profiling for children)",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": true,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "No profiling based on SCD",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: false,
      "EU AVMSD": false,
      AADC: false,
    },
    {
      Requirement: "Crisis response mechanism (Supervisory authority can impose special terms at moments notice)",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: false,
      "EU AVMSD": false,
      AADC: false
      },
      {
      Requirement: "Applies both to EU users and businesses based in the EU",
      GDPR: true,
      "UK DPA": true,
      ePD: true,
      "EU AI Act": true,
      DSA: true,
      OSB: true,
      "EU AVMSD": true,
      AADC: true
      },
      {
      Requirement: "Transparency reporting",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: false
      },
      {
      Requirement: "KYC for advertiser/sellers",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: true,
      "EU AVMSD": false,
      AADC: false
      },
      {
      Requirement: "Risk assessments",
      GDPR: true,
      "UK DPA": true,
      ePD: false,
      "EU AI Act": false,
      DSA: false,
      OSB: true,
      "EU AVMSD": false,
      AADC: false
      },
      {
      Requirement: "Moderate UGC posts",
      GDPR: false,
      "UK DPA": false,
      ePD: false,
      "EU AI Act": false,
      DSA: true,
      OSB: true,
      "EU AVMSD": true,
      AADC: false
      },
  ];