import MainNavigation from './MainNavigation.jsx'
import { Outlet } from 'react-router-dom';

export default function Layout() {
  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flexGrow: 1 }}>
        <MainNavigation />
        <Outlet />
      </div>
    </div>
  );
}
