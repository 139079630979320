import { Routes, Route } from 'react-router-dom';
import React from "react";
import Layout from './Pages/Layout';
import ScrollToTop from './components/utils/ScrollToTop';
import Overview from './Pages/Overview';
import ChildSafety from './Pages/ChildSafety';
import 'bootstrap/dist/css/bootstrap.min.css';
import { GeneralAssessment } from './Pages/GeneralAssessment';
import './index.css';

export default function App() {
  return (
    <>
      <ScrollToTop />
      <Routes>
        <Route element={<Layout />}>
          <Route
            path="/"
            element={<Overview />}
          />
          <Route
            path="child-safety"
            element={<ChildSafety />}
          />
          <Route
            path="general-assessment"
            element={<GeneralAssessment />}
          />
        </Route>
      </Routes>
    </>
  );
}
