import { Table } from "react-bootstrap";
import { CgCloseO } from "react-icons/cg";
import { GiConfirmed } from "react-icons/gi";
import { csData } from "../Data/EuChildSafetyOverview";
import { csDetail } from "../Data/EuChildSafetyDetail";
import { useState } from "react";
import { IoArrowBack } from 'react-icons/io5';

export default function ChildSafety() {

  const [modalInfo, setModalInfo] = useState([]);
  const [showDetails, setShowDetails] = useState(false);

  const handleDetailClose = () => {
    setShowDetails(false);
  };

  const tr = [<GiConfirmed style={{ color: 'green', cursor: 'pointer' }} />];
  const fs = [<CgCloseO style={{ color: 'red' }} />];

  const modalContent = (content, title, req) => {
    setModalInfo({
      body: content,
      header: title,
      requirement: req,
    })
    if (content.length > 1) {
      setShowDetails(true);
    } else {
      setShowDetails(false);
    }
  }
  
  return (
    <div className="content-container">
      <h1 className='page-title'>{showDetails ? `${modalInfo.requirement}` : 'EU Child Safety Overview'}</h1>
      <div className={`detail-container${showDetails ? ' animate' : ''}`}>
        <button onClick={handleDetailClose}><IoArrowBack style={{ paddingBottom: '2px' }} /> back</button>
        <div style={{ marginTop: '10px' }}>

          <div>
            <h4 style={{ padding: '10px 0px' }}>
              {modalInfo.header}
            </h4>
          </div>
          <div>
            <p style={{ padding: '10px 0px' }}>{modalInfo.body}</p>
          </div>
        </div>
      </div>
      <h6 className={`sub-header${showDetails ? ' animate' : ''}`}>Select a <span><GiConfirmed style={{ color: 'green' }} /></span> for details</h6>
      <Table borderless hover responsive="xl" className={`Table-content${showDetails ? ' animate' : ''}`}>
        <thead>
          <tr>
            <th>Requirement</th>
            <th>GDPR</th>
            <th>UK DPA</th>
            <th>ePD</th>
            <th>EU AI Act</th>
            <th>DSA</th>
            <th>OSB</th>
            <th>EU AVMSD</th>
            <th>AADC</th>
            <th>COPPA</th>
          </tr>
        </thead>
        <tbody>
          {csData.map((row, index) => (
            <tr key={index}>
              <td>{row.Requirement}</td>
              <td
                onClick={() =>
                  modalContent(csDetail[index].GDPR, "GDPR", row.Requirement)
                }
              >
                {row.GDPR ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(
                    csDetail[index]["UK DPA"],
                    "UK DPA",
                    row.Requirement
                  )
                }
              >
                {row["UK DPA"] ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(csDetail[index].ePD, "ePD", row.Requirement)
                }
              >
                {row.ePD ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(
                    csDetail[index]["EU AI Act"],
                    "EU AI Act",
                    row.Requirement
                  )
                }
              >
                {row["EU AI Act"] ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(csDetail[index].DSA, "DSA", row.Requirement)
                }
              >
                {row.DSA ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(csDetail[index].OSB, "OSB", row.Requirement)
                }
              >
                {row.OSB ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(
                    csDetail[index]["EU AVMSD"],
                    "EU AVMSD",
                    row.Requirement
                  )
                }
              >
                {row["EU AVMSD"] ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(csDetail[index].AADC, "AADC", row.Requirement)
                }
              >
                {row.AADC ? tr : fs}
              </td>
              <td
                onClick={() =>
                  modalContent(csDetail[index].COPPA, "COPPA", row.Requirement)
                }
              >
                {row.COPPA ? tr : fs}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}
